/* App.css or a global CSS file */

body {
  max-width: 100%; /* Set a maximum width to prevent horizontal scrolling */

  overflow-x: hidden; /* Hide horizontal overflow */

}
html{
  scroll-behavior: smooth;
  scroll-padding-top: 3rem;
}

/* Rest of your global styles */

