/* ContactPage.css */

/* Style the page container */
.contact-page {
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Style headings */
.contact-page h2 {
  color: #333;
}

.contact-page h3 {
  color: #555;
}

/* Style contact information */
.contact-info {
  margin-top: 20px;
}

.contact-info p {
  margin-bottom: 10px;
  color: #444;
}

/* Style form container */
.form-container {
  flex: 1;
  margin-right: 20px; /* Add some spacing between the form and map */
}

/* Style form */
.form-group {
  margin-bottom: 20px;
  box-sizing: border-box;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s ease-in-out;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff; /* Highlight border on focus */
}

.form-group textarea {
  resize: vertical;
}

/* Style submit button */
button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Style map (adjust the height as needed) */
.map {
  flex: 1;
  height: 775px; /* Set the height for the map container */
}

/* Responsive layout for mobile */
@media (max-width: 768px) {
  /* Reset flex layout for mobile */
  .contact-page {
    flex-direction: column;
  }

  /* Center-align the form and map on mobile */
  .form-container,
  .map {
    flex: 1;
    margin-right: 0; /* Remove spacing between form and map */
    margin-top: 20px;
  }
}
.contact-2-info {
  text-decoration: none;
  color: #080808;
}
span{
  color: #615b5b;
  font-weight: 400;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(172, 255, 47, 0.695);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  color: #030303;

  font-size: xx-large;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
