/* GalleryPage.css */

/* Styles for the gallery page */
.gallery-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center; /* Center align the content */
  }
  
  h2 {
    color: #0077b6;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  /* Image grid styles */
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    justify-items: center; /* Center-align grid items */
    margin-bottom: 20px;
  }
  
  /* Individual image item styles */
  .image-item {
 /* Background color for the image items */
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease; /* Add a smooth transform animation */
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .image-item:hover {
    transform: scale(1.05); /* Enlarge image item on hover */
  
    
  }
  
  img ,video{
    max-width: 100%;
    height: auto;
    border-radius: 5px; /* Add rounded corners to images */
  }
  
  p {
    margin-top: 10px;
    font-size: 14px;
  }
  
  /* Media query for smaller screens */
  @media screen and (max-width: 768px) {
    .image-grid ,.video-grid{
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust column width for smaller screens */
      gap: 10px; /* Reduce gap between items */
    }
    .image-item:hover {
      transform: scale(1.4); /* Enlarge image item on hover */
    }
.filter-options{
display:inline-block;


}


    
  }

/* GalleryPage.css */

.filter-options {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

button {
  background-color: #0077b6;
  color: #fff;
  padding: 10px 15px;
  margin: 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

button:hover {
  background-color: #005b82;
}

  
.image-item {
  opacity: 0;
  animation: fadeIn .7s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
} 