/* TestimonialsPage.css */

/* Styles for the testimonials page */
.testimonials-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

h2 {
  color: #0077b6;
  font-size: 28px;
  margin-bottom: 20px;
}

.testimonial-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-card:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.quote {
  font-size: 20px;
  color: #333;
  font-style: italic;
  margin-bottom: 10px;
  line-height: 1.4;
}

.author {
  font-size: 16px;
  color: #555;
  font-weight: bold;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .testimonial-card {
    padding: 10px;
  }

  .quote {
    font-size: 18px;
  }

  .author {
    font-size: 14px;
  }

  .avatar {
    width: 40px;
    height: 40px;
  }
}
