/* PasswordPage.css */

.password-page-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .password-page-container h2 {
    text-align: center;
    color: #333;
  }
  
  .password-page-container p {
    margin-bottom: 10px;
    color: #555;
  }
  
  .password-input {
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .success-message {
    color: #4caf50;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .error-message {
    color: #ff3333;
    font-weight: bold;
    margin-top: 10px;
  }
  
  /* Style for the "No, thank you. Proceed to Manage Posts" button */
  .proceed-button {
    background-color: #2196f3;
  }
  
  .proceed-button:hover {
    background-color: #0b7dda;
  }
  