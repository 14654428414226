/* Footer.css */

.footer {
  background-color: #0077b6;
  color: #fff;
  padding: 7.2px ; /* Smaller padding for a reduced height */
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10px;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
}

.footer p {
  margin: 0;
  font-size: 14px; /* Adjust the font size for a smaller footer */
  color: #dddada;

}

.footer a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.footer a:hover {
  color: #ffd700;
}
