/* Navbar.css */

/* Navbar styles */
nav {
    background-color: #0077b6;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  li {
    margin-right: 20px;
  }
  
  a {
    text-decoration: none;
    color: #fff;
    font-weight: bold; /* Add font-weight for links */
  }
  
  /* Logo container styles */
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  /* Logo styles */
  .logo {
    width: 60px;
    height: auto;
    margin-right: 10px; /* Add margin to separate the logo from text */
  }
  
  /* Logo text styles */
  .logo-text {
    display: flex;
    flex-direction: column;
    font-size: 18px; /* Adjust the font size as needed */
    color: #fff; /* Change the color of the text */
  }
  
  /* Hamburger icon styles */
  .hamburger {
    display: none; /* Hide by default on desktop */
    cursor: pointer;
    padding: 10px;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px auto;
    transition: 0.4s;
  }
  
  /* Media query to show/hide hamburger icon and menu */
  @media screen and (max-width: 821px) {
    /* Display hamburger icon on mobile */
    .hamburger {
      display: block;
    }
  
    /* Hide navigation links by default on mobile */
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: #0077b6;
      position: absolute;
      top: 80px;
      left: 0;
      right: 0;
      transform: scaleY(0); /* Initially closed with no height */
      transform-origin: top;
      transition: transform 0.4s ease-in-out;
      line-height: 40px;
    }
  
    ul.open {
      transform: scaleY(1); /* Open with full height */
    }
  
    /* Styles for open mobile menu */
    .bar.open:nth-child(1) {
      transform: rotate(-45deg) translate(-5px, 6px);
    }
  
    .bar.open:nth-child(2) {
      opacity: 0;
    }
  
    .bar.open:nth-child(3) {
      transform: rotate(45deg) translate(-5px, -6px);
    }
   
  }
  



 /* Navbar.css */

/* Style the logo text */
.logo-text {
display: block;
  align-items: center;
  font-size: 24px; /* Adjust the font size as needed */
 font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Style individual letters */
.logo-text .letter-l {
  color: #0398fb; /* Set the color for "L" */
}

.logo-text .letter-i {
  color: #0398fb; /* Set the color for "I" */
}

.logo-text .letter-t1 {
  color: yellow;; /* Set the color for the first "T" */
}

.logo-text .letter-t2 {
  color: yellow; /* Set the color for the second "T" */
}

.logo-text .letter-l2 {
  color: red; /* Set the color for "L" */
}

.logo-text .letter-e {
  color: red; /* Set the color for "E" */
}
.logo-text .desh{
  color: #fdfeff; /* Set the color for "I" */
}

.logo-text .letter-i2 {
  color: #d6dbd9; /* Set the color for "I" */
}
.logo-text .letter-q {
  color: #d6dbd9; /* Set the color for "Q" */
}
.logo-text .letter-s {
  color: #F1C40F; /* Set the color for "Q" */
}
.logo-text .letter- {
  color: #F1C40F; /* Set the color for "Q" */
}



.dropdown-details{
 color: #fafcfc;
}
.dropdown-details2{
  color: black;
}
span{
color: rgb(219, 215, 215);
}

ul li ul.dropdown li{
  display: block;
}
ul li ul.dropdown {
  position: absolute;
  z-index: 999;
  display: none;
}
ul li:hover ul.dropdown{
  display: block;
  line-height: 30px;
}
.Information2024 {
  color: white;
}