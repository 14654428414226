/* HomePage.css */

/* Style the home container */
.home-container {
  font-family: Arial, sans-serif;
  text-align: center;
  max-width: 100%;
  padding: 15px;
  background-image: url('../assets/backgrnd.jpg'); /* Check the image path */
  background-size: cover;
  background-position: center;
  color: #0e0d0d; /* Change text color for better readability on the background */
  padding-top: 0px;
  font-weight: 300;
}

/* Style the Hero section */
.hero {
  padding: 100px 0;

}

.hero h2 {
  font-size: 35px;
  margin-bottom: 20px;
  margin-top: 60px;
}

.hero p {
  font-size: 18px;
  color: #313131;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  
}

/* Style the content container */
.content-container {
  text-align: left; /* Align content to the left */
  max-width: 800px; /* Set a maximum width for the content */
  margin: 0 auto; /* Center align the content horizontally */
  padding: 20px;
  margin-top: 130px;
  background-color: white;
  backdrop-filter: blur(8px); /* Apply a blur effect to the background */
  opacity: 0.9; /* Adjust the opacity for the glass effect */
  
}

/* Style the Subheadline */
.subheadline {
  font-size: 24px;
  margin: 40px 0;
}

/* Style the CTA button */
.cta-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 18px;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #0056b3;
}

.subheadline p, .cta p {
  color: #242323;
}

@media screen and (max-width: 768px) {
  /* Display hamburger icon on mobile */
  .home-container {
    background-image: url('../assets/background.jpg'); /* Check the image path */
    background-size: cover;
    background-position: center;
  }
 

.apart-ul{
  display: block;
  
}
}

@media screen and (max-width: 803px){
  .apart-ul{
    text-align: start;
  display: contents;
  list-style:circle ;
  }
}


