.container-main{
  width: 100%;
  background-image: url('../assets/feesBack.jpg'); /* Check the image path */
  background-size: cover;
  background-position: center;
  height: 13%;
}
.container {
  font-family: Arial, sans-serif;
  max-width: 550px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 80%;
  margin-bottom: 30px;
}

h2 {
  color: #0077b6;
  margin-top: -9px;
}

h3 {
  color: #333;
  margin-top: 6px;
}

p {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

ul.fee-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

li.fee-item {
  margin-bottom: 10px;
}

.fees-section {
  margin-top: 20px;
}

.cta-button {
  background-color: #0077b6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
}

.cta-button:hover {
  background-color: #0056a5;
}
.fee-list{
  display: contents;
}


/* Media query for mobile views where fee-list is hidden */


